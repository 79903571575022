import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TypographyCheckbox,
  Box,
  ListItemText,
  Typography,
  Checkbox
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormContainer from 'components/FormContainer';
import { LoadingButton } from '@mui/lab';
import 'dayjs/locale/fr';
import dayjs, { Dayjs } from 'dayjs';
import usePatient from 'hooks/usePatient';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import useSuperposition from 'hooks/useSuperposition';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------

const LOCALE_DEF = 'fr';
export default function FormSuperposition({ onFinish }) {
  const { pointtracings } = usePointTracing();
  const { patient } = usePatient();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onFinish();
  };
  if (!patient) return <></>;
  const disabledField = patient && patient.id && patient.lockOff;
  /**2 pointtracings minimum to create a superposition  */
  const isCanEditSuper = pointtracings && pointtracings.length > 1 ? true : false;
  return (
    <>
      <Button disabled={disabledField} variant="outlined" onClick={handleClickOpen}>
        Ajouter une superposition
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          {isCanEditSuper ? (
            <FormTracing onComplete={handleClose} />
          ) : (
            <>
              <Typography variant="h4">
                Veuillez créer au moins 2 tracés pour effectuer la superposition
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const FormTracing = ({ editSuper, onComplete }) => {
  const [tracingsSelected, settracingsSelected] = useState([]);
  const { pointtracings } = usePointTracing();
  const { notif } = useNotification();
  const { patient } = usePatient();
  const { refs } = useReferentiel();
  const { saveSuperposition, initSuperposition } = useSuperposition();

  useEffect(() => {
    if (!editSuper) return;
    if (!editSuper.pointTracings) return;
    settracingsSelected(editSuper.pointTracings.filter((it) => it.id));
  }, [editSuper]);

  const formSchema = Yup.object().shape({
    label: Yup.string().required(`Veuillez renseigner le nom de la superposition`),
    executedat: Yup.string().required('Veuillez renseigner la date de réalisation')
  });

  const formik = useFormik({
    initialValues: {
      label: editSuper && editSuper.label ? editSuper.label : '',
      executedat: editSuper && editSuper.executedat ? editSuper.executedat : dayjs()
    },
    validationSchema: formSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!patient) return;
        if (!pointtracings) return;
        await saveSuperposition({
          ...values,
          patient: patient.id,
          pointTracings: tracingsSelected
        });
        await initSuperposition();
        onComplete();
        resetForm();
        notif('Sauvegarde réussie');
      } catch (error) {
        notif(null, error);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
    handleChange,
    resetForm
  } = formik;

  const handleChangeTracing = (event) => {
    const {
      target: { value }
    } = event;
    settracingsSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormContainer title="Nouvelle superposition">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE_DEF}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Nom de la superposition"
                {...getFieldProps('label')}
                error={Boolean(touched.label && errors.label)}
                helperText={touched.label && errors.label}
              />
              <DesktopDatePicker
                onChange={(value) => setFieldValue('executedat', value, true)}
                value={values.executedat}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.executedat && errors.executedat)}
                    helperText={touched.executedat && errors.executedat}
                    label="Date de réalisation"
                    margin="normal"
                    name="birth"
                    variant="standard"
                    fullWidth
                    {...params}
                  />
                )}
              />
              <TextField
                fullWidth
                select
                multiple
                label="Tracés choisis"
                {...getFieldProps('pointTracings')}
                error={Boolean(touched.pointTracings && errors.pointTracings)}
                helperText={touched.pointTracings && errors.pointTracings}
                SelectProps={{
                  multiple: true,
                  value: tracingsSelected,
                  renderValue: (selected) => {
                    if (!pointtracings) return;
                    const arr = pointtracings.filter((it) => selected.includes(it.id));
                    const arrTitle = arr.map((it) => it.label);
                    return arrTitle.join(', ');
                  },
                  onChange: handleChangeTracing
                }}
                my={2}
              >
                {pointtracings.map((c, i) => {
                  return (
                    <MenuItem key={i} value={c.id}>
                      <Checkbox checked={tracingsSelected.indexOf(c.id) > -1} />
                      <Box display="flex" alignItems="center">
                        <ListItemText primary={c.label} />
                      </Box>
                    </MenuItem>
                  );
                })}
              </TextField>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Valider
              </LoadingButton>
            </Stack>
          </LocalizationProvider>
        </Form>
      </FormikProvider>
    </FormContainer>
  );
};
