import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PolylineIcon from '@mui/icons-material/Polyline';
import { styled, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import DoneIcon from '@mui/icons-material/Done';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import {
  SVGPathData,
  SVGPathDataTransformer,
  SVGPathDataEncoder,
  SVGPathDataParser
} from 'svg-pathdata';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import { path } from 'd3-path';

import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_TOOLBAR_WIDTH,
  SVGEDITOR_TRACING_STROKEWIDTH
} from 'config/appConfig';
import 'components/svg/svg.css';
import useNotification from 'hooks/useNotification';
import useEditPath from 'hooks/useEditPath';
import {
  setMovePoint,
  getMovePoint,
  getControlPointFromTracing,
  updatePath,
  getBoundingBoxCenter,
  toRel,
  parseToCommands,
  drawTransfertGuide
} from 'utils/pathUtil';
import {
  ROTATE,
  TRANSLATE,
  SCALE,
  getTransform,
  setTransform,
  toDegrees,
  angleBetweenPoints,
  distanceBetweenPoints,
  distance,
  toSvgData,
  xmlToBase64
} from 'utils/d3Util';
import usePatient from 'hooks/usePatient';
import ResetSuperBtn from './ResetSuperBtn';

import SvgEditorCloseBtn from './SvgEditorCloseBtn';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
/*  width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`, */
const TRANSITION_TIME = 750;
const SupersSvgEditor = React.forwardRef(
  (
    {
      title,
      handleClose,
      img64,
      superpositions,
      onComplete,
      editTracingStep,
      onDeleteTracing,
      onReset,
      points,
      displayPoints,
      onChangeDisplayPoints,
      ...props
    },
    ref
  ) => {
    const { patient } = usePatient();
    const disabledField = patient && patient.id && patient.lockOff;

    const [currentSuperposition, setcurrentSuperposition] = useState(null);
    const [tracings, settracings] = useState(null);
    const { notif } = useNotification();
    const svgRef = useRef(null);
    const zoomHandler = zoom();
    const handler = drag();
    const { refs } = useReferentiel();
    const {
      mixins: { toolbar }
    } = useTheme();
    // ----------------------------------------------------------------------
    const { editSvg, resetEditPath, initEditPath, handleUpdateChangePath } = useEditPath();
    const timer = useRef(null);
    //--------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      try {
        const g = d3.select('#pointGroup');
        g.selectAll('circle').attr('opacity', displayPoints ? 1 : 0);
      } catch (error) {
        console.error(error);
      }
    }, [svgRef, displayPoints]);
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!points) return;
      drawPoints(points);
    }, [points, svgRef]);
    // ----------------------------------------------------------------------
    /* function to draw all points in svg */
    const drawPoints = (myPoints) => {
      try {
        const g = d3.select('#pointGroup');
        const circles = myPoints.map((it, i) => {
          const id = `id_${it.label}`.replaceAll(' ', '-').toLowerCase();
          return {
            id,
            x: it.xposition,
            y: it.yposition,
            color: it.color,
            code: it.code,
            opacity: displayPoints ? 1 : 0,
            className: '.point'
          };
        });
        g.selectAll('circle')
          .data(circles)
          .join('circle')
          .attr('id', (d) => d.id)
          .attr('code', (d) => d.code)
          .attr('cx', (d) => d.x)
          .attr('cy', (d) => d.y)
          .attr('r', Number(SVGEDITOR_POINT_RADIUS) + 2)
          .attr('fill', 'transparent')
          .attr('stroke', (d) => d.color)
          .attr('name', (d) => d.name)
          .attr('opacity', (d) => d.opacity);
      } catch (error) {
        console.error(error);
      }
    };
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!superpositions) return;
      setcurrentSuperposition(superpositions[0]);
    }, [superpositions]);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!currentSuperposition) return;
      const newTracing = [...currentSuperposition.tracings];
      /*
      const { points, radio, transfertPath } = currentSuperposition;
      if (transfertPath) {
        newTracing.push(transfertPath);
      } else {
        const rectTransfert = drawTransfertGuide(points, radio);
        const newTransfertGuide = {
          id: 'guideTransfert',
          code: 100,
          label: 'Guide de transfert',
          color: 'red',
          imgpath: rectTransfert,
          movable: true
        };
        newTracing.push(newTransfertGuide);
      }
*/
      settracings(newTracing);
    }, [currentSuperposition]);

    // ----------------------------------------------------------------------
    useEffect(() => {
      resetEditPath();
      return () => {
        resetEditPath();
      };
    }, []);
    useEffect(() => {
      if (!timer || !timer.current) return;
      // clear on component unmount
      return () => {
        clearTimeout(timer.current);
      };
    }, []);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;

      const svg = d3.select(svgRef.current);
      svg.select('#radioImage').attr('opacity', 1);
      svg.select('#radioImage').style('filter', 'contrast(1) brightness(1) saturate(1)');
      /* zoom handle */
      svg.call(
        zoomHandler
          .extent([
            [0, 0],
            [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
          ])
          .scaleExtent([1, 8])
          .on('zoom', zoomed)
      );
    }, [svgRef.current]);
    /* zoom function */
    const zoomed = ({ transform }) => {
      if (!svgRef || !svgRef.current) return;
      const g = d3.select('#globalGroup');
      g.attr('transform', transform);
    };
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!Array.isArray(tracings) || tracings.length === 0) return;
      if (!currentSuperposition) return;
      const timeout = setTimeout(() => {
        const gGobal = d3.select('#globalGroup');
        d3.select('#tracingGroup').remove();
        const g = gGobal.append('g').attr('id', 'tracingGroup');
        const { points, radio } = currentSuperposition;
        tracings
          .filter((it) => it.movable)
          .forEach((it, i) => {
            const { id, code, label, color, imgpath, fill } = it;
            const newId = `id_${id}`;
            const idGroup = `group_${id}`;
            const d = { id: newId, code, color, label, imgpath, idGroup };
            /*  remove old tracings and add new one */
            d3.select(`#${idGroup}`).remove();
            g.append('g')
              .attr('id', idGroup)
              .append('path')
              .attr('id', newId)
              .attr('idGroup', idGroup)
              .attr('code', code)
              .attr('d', imgpath)
              .attr('fill', fill ? fill : 'none')
              .attr('stroke', color)
              .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
            const pointMove = getMovePoint(imgpath);
            if (pointMove) {
              const { x, y, width, height } = pointMove;
              const idUse = `use_${idGroup}`;
              d3.select(`#${idGroup}`)
                .append('use')
                .attr('id', idUse)
                .attr('idGroup', idGroup)
                .attr('idPath', newId)
                .attr('color', color)
                .attr('href', '#pointer')
                .attr('x', x)
                .attr('y', y)
                .attr('fill', color)
                .attr('stroke', color)
                .attr('stroke-width', '1px')
                .on('mouseover', (event) => {
                  const isEditing = d3.select(`#${idGroup}`).attr('editing');
                  if (isEditing) return;
                  d3.select(`#${idUse}`).attr('opacity', 0.5).style('cursor', 'move');
                })
                .on('mouseout', (event) => {
                  const isEditing = d3.select(`#${idGroup}`).attr('editing');
                  if (isEditing) return;
                  d3.select(`#${idUse}`).attr('opacity', 1).style('cursor', 'grab');
                })
                .on('click', (_, e) => handleDisplayPathCtrl(d))
                .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended));
            }
          });
        /* not movable */
        tracings
          .filter((it) => !it.movable)
          .forEach((it, i) => {
            const { id, code, label, color, imgpath, fill } = it;
            const newId = `id_${id}`;
            const idGroup = `group_${id}`;
            const d = { id: newId, code, color, label, imgpath, idGroup };
            /*  remove old tracings and add new one */
            d3.select(`#${idGroup}`).remove();
            g.append('g')
              .attr('id', idGroup)
              .append('path')
              .attr('id', newId)
              .attr('idGroup', idGroup)
              .attr('code', code)
              .attr('d', imgpath)
              .attr('fill', fill ? fill : 'none')
              .attr('stroke', color)
              .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
          });
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }, [tracings]);
    // ----------------------------------------------------------------------
    /* click on path line */
    const handleDisplayPathCtrl = (d) => {
      if (disabledField) return;
      const circles = getControlPointFromTracing(d);
      if (!circles) return;
      /* disable all editing group before starting a new one */
      disableAllGroupEditing();
      const { id, idGroup } = d;
      const currentPath = d3.select(`#${id}`).attr('d');
      const centerPoint = getBoundingBoxCenter(d3.select(`#${id}`));
      initEditPath(id, currentPath, centerPoint);
      /* hide move icon */
      const idUse = `use_${idGroup}`;
      d3.select(`#${idUse}`).attr('opacity', 0);
      d3.select(`#${idGroup}`).attr('editing', true);
      const gGroup = d3.select(`#${idGroup}`);
      const g = gGroup.append('g').attr('id', 'controlGroup');
      g.selectAll('circle')
        .data(circles)
        .join('circle')
        .attr('id', (d) => d.id)
        .attr('idPath', (d) => d.idPath)
        .attr('label', (d) => d.label)
        .attr('cx', (d) => d.cx)
        .attr('cy', (d) => d.cy)
        .attr('r', Number(SVGEDITOR_POINT_RADIUS) + 2)
        .attr('fill', 'transparent')
        .attr('stroke', (d) => d.color)
        .on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move');
        })
        .on('mouseout', function (d) {})
        .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
        .raise();
      const bbox = d3.select(`#${idGroup}`).node().getBBox();
      if (bbox) {
        const { x, y } = bbox;
        const { idText } = d;
        g.append('text')
          .attr('id', idText)
          .attr('x', x)
          .attr('y', y - 10)
          .style('font-size', '14px')
          .style('fill', 'red')
          .text('Fermer')
          .on('mouseover', function (d) {
            d3.select(this).style('cursor', 'pointer');
            resetEditPath();
          })
          .on('mouseout', function (d) {})
          .on('click', (_, d) => {
            d3.select(`#${idGroup}`).attr('editing', false);
            g.remove();
            d3.select(`#${idUse}`).attr('opacity', 1);
          });
      }
    };
    /* disable all group editing */
    const disableAllGroupEditing = () => {
      if (disabledField) return;
      try {
        if (!tracings) return;
        tracings.forEach((it, i) => {
          const { id } = it;
          const idGroup = `group_${id}`;
          const idUse = `use_${idGroup}`;
          const g = d3.select(`#${idGroup}`);
          g.attr('editing', false);
          const controlGrp = g.select('#controlGroup');
          controlGrp.remove();
          d3.select(`#${idUse}`).attr('opacity', 1);
        });
      } catch (error) {
        console.error(error);
      }
    };
    // ----------------------------------------------------------------------
    /** drag functions */
    const dragstarted = (event, d) => {
      if (disabledField) return;
      if (d) {
        /* move controls points */
        d3.select(`#${d.id}`).raise().attr('stroke', 'black');
        d3.select('#controlGroup').attr('cursor', 'grabbing');
      } else {
        /* move path */
        const { sourceEvent } = event;
        if (!sourceEvent) return;
        const { target } = sourceEvent;
        const movePoint = d3.select(target);
        /** when user chose one path , disable all draggable action of another and when dragended reset field isDragging for all */
        actionDrag(movePoint, 'start');
        d3.select(`#${movePoint.attr('idPath')}`).attr('stroke', 'black');
        movePoint.raise().attr('stroke', 'black');
      }
    };
    const actionDrag = (movePoint, action) => {
      let opacity = 1;
      let displayValue = 'block';
      let dragging = null;
      if (action === 'start') {
        opacity = 0.5;
        displayValue = 'none';
        dragging = true;
      }
      const dragIdGroup = d3.select(`#${movePoint.attr('idGroup')}`).attr('id');
      /** re-enable all   group */
      tracings.forEach((it, i) => {
        const { id } = it;
        const idGroup = `group_${id}`;
        const isGroupNotDraggabled = String(dragIdGroup) === String(idGroup) && action === 'start';
        if (!isGroupNotDraggabled || action === 'end') {
          const idUse = `use_${idGroup}`;
          d3.select(`#${idGroup}`).attr('opacity', opacity);
          d3.select(`#${idUse}`).style('display', displayValue);
        }
      });

      d3.select(`#${dragIdGroup}`).attr('isDragging', dragging);
    };
    const dragged = (event, d) => {
      if (disabledField) return;
      if (d) {
        /* move controls points */
        const { id, idPath } = d;
        d3.select(`#${id}`)
          .attr('cx', (d.x = event.x))
          .attr('cy', (d.y = event.y));
        updatePath(idPath, { ...d, x: event.x, y: event.y });
      } else {
        /* move path */
        const { x, y, dx, dy } = event;
        const { sourceEvent } = event;
        if (!sourceEvent) return;
        const { target } = sourceEvent;
        let movePoint = d3.select(target);

        if (!movePoint) return;
        if (!movePoint.attr('idPath')) {
          const groupDragended = getIdPathOnDragEnded();

          movePoint = d3.select(`#${groupDragended.id}`);
        }

        const idPath = movePoint.attr('idPath');
        if (!idPath) return;
        const targetX = Number(movePoint.attr('x')) + Number(dx);
        const targetY = Number(movePoint.attr('y')) + Number(dy);
        movePoint.attr('x', targetX).attr('y', targetY);
        setMovePoint(idPath, { x: targetX, y: targetY });
      }
    };

    const dragended = (event, d) => {
      if (disabledField) return;
      let idPathUpdated = null;
      if (d) {
        /* move controls points */
        const { id, idPath, isOnPath } = d;
        d3.select(`#${id}`).attr('stroke', d.color);
        idPathUpdated = idPath;
      } else {
        /* move path */
        const { sourceEvent } = event;
        if (!sourceEvent) return;
        const { target } = sourceEvent;

        let movePoint = d3.select(target);
        if (!movePoint.attr('idPath')) {
          const groupDragended = getIdPathOnDragEnded();
          movePoint = d3.select(`#${groupDragended.id}`);
        }
        let idPath = movePoint.attr('idPath');
        if (!idPath) return;
        if (!movePoint) return;
        const color = movePoint.attr('color');
        movePoint.attr('stroke', color);
        d3.select(`#${idPath}`).attr('stroke', color);
        idPathUpdated = idPath;
        actionDrag(movePoint, 'end');
      }
      if (idPathUpdated) {
        handleUpdateChangePath(idPathUpdated);
      }
    };
    const getIdPathOnDragEnded = () => {
      try {
        if (!tracings) return null;
        const tracingOnDragging = tracings.find((it) => {
          const { id } = it;
          const isDragging = d3.select(`#group_${id}`).attr('isDragging');
          return isDragging === 'true';
        });
        if (!tracingOnDragging) return null;
        const { id } = tracingOnDragging;
        return { id: `use_group_${id}`, idPath: `id_${id}`, idGroup: `group_${id}` };
      } catch (error) {
        console.error(error);
      }
      return null;
    };
    // ----------------------------------------------------------------------
    /* validate xml */
    const handleValidate = async () => {
      if (disabledField) return;
      try {
        if (!superpositions) return;
        if (!tracings) return;
        if (!currentSuperposition) return;
        if (!svgRef || !svgRef.current) return;
        if (!Array.isArray(tracings) || tracings.length < 1) return;
        const svg = d3.select(svgRef.current);
        disableAllGroupEditing();
        svg.style('background-image', null);
        svg
          .transition()
          .duration(TRANSITION_TIME)
          .selectAll('g')
          .attr('transform', 'translate(0,0) scale(1)');

        timer.current = setTimeout(async () => {
          const newTracings = tracings.map((it) => {
            const { id } = it;
            try {
              const newId = `id_${id}`;
              let contentPath = d3.select(`#${newId}`).attr('d');
              let commands = [];
              if (contentPath) {
                contentPath = toRel(contentPath);
                commands = parseToCommands(contentPath);
              }
              return { ...it, imgpath: contentPath, commands: commands };
            } catch (error) {
              console.error(error);
              return { ...it };
            }
          });
          const xmlData = xmlToBase64(toSvgData(document.getElementById('svg')));
          const newSuperpositions = superpositions.map((it) => {
            const newIt = { ...it };
            if (Number(newIt.code) === Number(currentSuperposition.code)) {
              const newTransfert = newTracings.find((t) => Number(t.code) === 100);
              if (newTransfert) {
                newIt.transfertPath = { ...newTransfert };
              }
              newIt.tracings = [...newTracings.filter((t) => Number(t.code) !== 100)]; //except the transfert path
              newIt.xmlData = xmlData;
            }
            return { ...newIt };
          });
          onComplete(newSuperpositions);
        }, Number(TRANSITION_TIME + 50));
      } catch (error) {
        notif(null, error);
      }
    };
    // ----------------------------------------------------------------------
    const onSelectSuper = (it) => {
      setcurrentSuperposition(it);
    };
    const handleReset = () => {
      if (disabledField) return;
      if (!currentSuperposition) return;
      console.log(currentSuperposition);
      onReset(currentSuperposition);
    };
    // ----------------------------------------------------------------------
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
            zIndex: 1300
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              {title ? title : 'Modifier les tracings tracés.'}
            </Typography>
            <SvgEditorCloseBtn onClose={handleClose} />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: SVGEDITOR_TOOLBAR_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: SVGEDITOR_TOOLBAR_WIDTH,
              boxSizing: 'border-box'
            }
          }}
          variant="permanent"
          anchor="left"
        >
          <DrawerHeader />
          <Divider />
          <List sx={{ mt: 4 }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                disabled={disabledField}
                sx={{
                  minHeight: 48,
                  justifyContent: 'initial',
                  px: 2.5
                }}
                onClick={() => handleValidate()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center'
                  }}
                >
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText primary="Valider les tracés" />
              </ListItemButton>
            </ListItem>
          </List>
          {/* reset button */}

          {!disabledField && (
            <>
              <Divider />
              <List sx={{ mt: 4 }}>
                <ListItem disablePadding sx={{ display: 'block', color: 'red' }}>
                  <ResetSuperBtn svgRef={svgRef} onReset={onReset} />
                </ListItem>
              </List>
            </>
          )}
          <Divider />
          <Box sx={{ my: 3, px: 2 }}>
            <CheckboxDisplayPoints
              displayPoints={displayPoints}
              onChangeDisplay={onChangeDisplayPoints}
            />
          </Box>
          <Divider />
          <List sx={{ mt: 4 }}>
            {superpositions.map((it, i) => {
              return (
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                  key={i}
                  selected={
                    currentSuperposition
                      ? Number(it.code) === Number(currentSuperposition.code)
                      : false
                  }
                >
                  <ListItemButton
                    sx={{
                      justifyContent: 'initial',
                      px: 2.5
                    }}
                    onClick={() => onSelectSuper(it)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center'
                      }}
                    >
                      <PolylineIcon />
                    </ListItemIcon>
                    <ListItemText primary={it.label} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            px: 3,
            width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`,
            height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
            marginTop: `${toolbar?.minHeight + 10}px`,
            overflow: 'hidden'
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="svg"
            ref={svgRef}
            viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
            style={{
              backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
              height: `100%`
            }}
            preserveAspectRatio="xMinYMin"
          >
            <defs>
              <g id="pointer" transform="scale(0.8)">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                  focusable="false"
                  aria-hidden="true"
                  data-testid="ControlCameraIcon"
                  tabIndex="-1"
                  title="ControlCamera"
                >
                  <path d="M15.54 5.54 13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92 1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10 1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </g>
            </defs>
            <g id="globalGroup" cursor="grab">
              <image id="radioImage" width="100%" height="100%" xlinkHref={img64} />
              <g id="pointGroup" />
              <g id="tracingGroup" />
            </g>
          </svg>
        </Box>
      </Box>
    );
  }
);

export default SupersSvgEditor;

const CheckboxDisplayPoints = ({ displayPoints, onChangeDisplay }) => {
  const onChange = (event) => {
    const checkDisplay = event.target.checked;
    onChangeDisplay(checkDisplay);
  };
  return (
    <>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Affichage des points</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={displayPoints} onChange={onChange} name="text" />}
            label="Afficher les points"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};
