import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import DoneIcon from '@mui/icons-material/Done';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_POINT_TEXTFONTSIZE,
  SVGEDITOR_TOOLBAR_WIDTH
} from 'config/appConfig';
import 'components/svg/svg.css';
import { distanceBetweenPoints, toSvgData, xmlToBase64 } from 'utils/d3Util';
import useNotification from 'hooks/useNotification';
import SvgToImgDownload from 'components/media/SvgToImgDownload';
import SvgEditorCloseBtn from './SvgEditorCloseBtn';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
/*  width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`, */
const TRANSITION_TIME = 750;
const PointsSvgEditor = React.forwardRef(
  ({ title, handleClose, img64, points, onComplete, ...props }, ref) => {
    const [updatedAt, setupdatedAt] = useState(null);
    const { notif } = useNotification();
    const svgRef = useRef(null);
    const zoomHandler = zoom();
    const handler = drag();
    const { refs } = useReferentiel();
    const {
      mixins: { toolbar }
    } = useTheme();
    const timer = useRef(null);
    useEffect(() => {
      if (!timer || !timer.current) return;
      // clear on component unmount
      return () => {
        clearInterval(timer.current);
      };
    }, []);

    useEffect(() => {
      if (!svgRef || !svgRef.current) return;

      const svg = d3.select(svgRef.current);
      svg.select('#radioImage').attr('opacity', 1);
      svg.select('#radioImage').style('filter', 'contrast(1) brightness(1) saturate(1)');
      /* zoom handle */
      svg.call(
        zoomHandler
          .extent([
            [0, 0],
            [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
          ])
          .scaleExtent([1, 8])
          .on('zoom', zoomed)
      );
    }, [svgRef.current]);
    /* zoom function */
    const zoomed = ({ transform }) => {
      if (!svgRef || !svgRef.current) return;
      const g = d3.select('#globalGroup');
      g.attr('transform', transform);
    };
    useEffect(() => {
      if (!points) return;
      const timeout = setTimeout(() => {
        const g = d3.select('#pointGroup');
        const circles = points.map((it, i) => {
          const id = `id_${it.id}`;
          const idText = `id_text_${it.id}`;
          return {
            x: it.xposition,
            y: it.yposition,
            color: it.color,
            code: it.code,
            id,
            idText,
            name: it.name,
            label: `${it.label}-${it.code}`,
            priority: it.priority,
            className: '.point'
          };
        });
        g.selectAll('circle')
          .data(circles)
          .join('circle')
          .attr('id', (d) => d.id)
          .attr('code', (d) => d.code)
          .attr('cx', (d) => d.x)
          .attr('cy', (d) => d.y)
          .attr('r', Number(SVGEDITOR_POINT_RADIUS) + 2)
          .attr('fill', 'transparent')
          .attr('stroke', (d) => d.color)
          .attr('name', (d) => d.name)
          .on('mouseover', function (d) {
            d3.select(this).style('cursor', 'move');
          })
          .on('mouseout', function (d) {})
          .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended));
        /* add text */
        g.selectAll('text')
          .data(circles)
          .join('text')
          .attr('id', (d) => d.idText)
          .attr('x', (d) => d.x - SVGEDITOR_LABEL_SPACING - 30)
          .attr('y', (d) => d.y - SVGEDITOR_LABEL_SPACING)
          .style('font-size', SVGEDITOR_POINT_TEXTFONTSIZE)
          .style('fill', (d) => d.color)
          .text((d) => d.label);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }, [points]);

    /** drag functions */
    const dragstarted = (event, d) => {
      d3.select(`#${d.id}`).raise().attr('stroke', 'black');
      d3.select('#pointGroup').attr('cursor', 'grabbing');
      /** add plus in current circle */
      d3.select('#pointGroup').append('line').attr('id', 'plus_vertical');
      d3.select('#pointGroup').append('line').attr('id', 'plus_horizontal');
      setPosPlusCircle(d);
    };

    const dragged = (event, d) => {
      const idCircle = d.id;
      const idText = d.id.replace('id', 'id_text');
      d3.select(`#${idCircle}`)
        .attr('cx', (d.x = event.x))
        .attr('cy', (d.y = event.y));

      /** move plus in current circle */
      setPosPlusCircle(d);
    };

    const dragended = (event, d) => {
      const idCircle = d.id;
      const idText = d.id.replace('id', 'id_text');
      d3.select(`#${idCircle}`).attr('stroke', d.color);
      d3.select('#pointGroup').attr('cursor', 'grab');
      const xTo = d3.select(`#${idCircle}`).attr('cx');
      const yTo = d3.select(`#${idCircle}`).attr('cy');
      d3.select(`#${idText}`)
        .attr('x', (d) => d.x - SVGEDITOR_LABEL_SPACING - 30)
        .attr('y', (d) => d.y - SVGEDITOR_LABEL_SPACING);
      setupdatedAt(new Date().getTime());

      /** remove plus in current circle */
      d3.select('#plus_vertical').transition().duration(1000).style('opacity', 0).remove();
      d3.select('#plus_horizontal').transition().duration(1000).style('opacity', 0).remove();
    };
    /** plus circle control */
    const setPosPlusCircle = (d) => {
      try {
        const { color, x, y } = d;
        const { xLine1, y1Line1, y2Line1, x1Line2, x2Line2, yLine2 } = getPosOfPlusCircle(x, y);
        d3.select('#plus_vertical')
          .attr('x1', xLine1)
          .attr('x2', xLine1)
          .attr('y1', y1Line1)
          .attr('y2', y2Line1)
          .attr('stroke', color);
        d3.select('#plus_horizontal')
          .attr('x1', x1Line2)
          .attr('x2', x2Line2)
          .attr('y1', yLine2)
          .attr('y2', yLine2)
          .attr('stroke', color);
      } catch (error) {
        console.error(error);
      }
    };
    const getPosOfPlusCircle = (x, y) => {
      const circleR = Number(SVGEDITOR_POINT_RADIUS) + 2;
      const xLine1 = x;
      const y1Line1 = y - circleR;
      const y2Line1 = y + circleR;
      const x1Line2 = x - circleR;
      const x2Line2 = x + circleR;
      const yLine2 = y;
      return { xLine1, y1Line1, y2Line1, x1Line2, x2Line2, yLine2 };
    };
    /**END plus circle control */
    /* validate xml */
    const handleValidate = async () => {
      try {
        if (!svgRef || !svgRef.current) return;
        if (!Array.isArray(points) || points.length < 1) return;
        const svg = d3.select(svgRef.current);
        svg
          .transition()
          .duration(TRANSITION_TIME)
          .selectAll('g')
          .attr('transform', 'translate(0,0) scale(1)');
        const arrPoints = [];
        d3.select('#pointGroup')
          .selectAll('circle')
          .filter((it) => {
            arrPoints.push(it);
            return true;
          });
        const newPoints = arrPoints.map((it) => {
          const initPoint = points.find((p) => Number(it.code) === Number(p.code));
          return {
            ...initPoint,
            xposition: it.x,
            yposition: it.y
          };
        });

        onComplete(newPoints);
      } catch (error) {
        notif(null, error);
      }
    };
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
            zIndex: 1300
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              {title ? title : 'Modifier les points tracés.'}
            </Typography>
            <SvgEditorCloseBtn onClose={handleClose} />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: SVGEDITOR_TOOLBAR_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: SVGEDITOR_TOOLBAR_WIDTH,
              boxSizing: 'border-box'
            }
          }}
          variant="permanent"
          anchor="left"
        >
          <DrawerHeader />
          <Divider />
          <List sx={{ mt: 4 }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: 'initial',
                  px: 2.5
                }}
                onClick={() => handleValidate()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center'
                  }}
                >
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText primary="Valider les mesures" sx={{ opacity: 1 }} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            px: 3,
            width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`,
            height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
            marginTop: `${toolbar?.minHeight + 10}px`,
            overflow: 'hidden'
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="svg"
            ref={svgRef}
            viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
            style={{
              backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
              height: `100%`,
              width: `100%`
            }}
            preserveAspectRatio="xMinYMin"
          >
            <g id="globalGroup" cursor="grab">
              <image id="radioImage" width="100%" height="100%" xlinkHref={img64} />
              <g id="pointGroup" />
              <g id="tracingGroup" opacity="0" />
            </g>
          </svg>
          <SvgToImgDownload svgEl="svg" />
        </Box>
      </Box>
    );
  }
);

export default PointsSvgEditor;
