import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import DoneIcon from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_TOOLBAR_WIDTH
} from 'config/appConfig';
import 'components/svg/svg.css';
import { distanceBetweenPoints, toSvgData, xmlToBase64 } from 'utils/d3Util';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
/*  width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`, */
const TRANSITION_TIME = 750;
export default function RadioSvgEditor({
  editPoint,
  handleClose,
  editRadio,
  img64,
  points,
  ...props
}) {
  const { initPointTracing, pointtracings, saveRadioStep } = usePointTracing();
  const [distanceM, setdistanceM] = useState(0);
  const { notif } = useNotification();
  const svgRef = useRef(null);
  const zoomHandler = zoom();
  const handler = drag();
  const { refs } = useReferentiel();
  const {
    mixins: { toolbar }
  } = useTheme();
  const timer = useRef(null);
  useEffect(() => {
    if (!timer || !timer.current) return;
    // clear on component unmount
    return () => {
      clearInterval(timer.current);
    };
  }, []);
  useEffect(() => {
    if (!editRadio || !editRadio.distance) return;
    setdistanceM(editRadio.distance);
  }, [editRadio]);
  useEffect(() => {
    if (!svgRef || !svgRef.current) return;

    const svg = d3.select(svgRef.current);
    /* zoom handle */
    svg.call(
      zoomHandler
        .extent([
          [0, 0],
          [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
        ])
        .scaleExtent([1, 8])
        .on('zoom', zoomed)
    );
  }, [svgRef.current]);
  /* zoom function */
  const zoomed = ({ transform }) => {
    if (!svgRef || !svgRef.current) return;
    const g = d3.select('#globalGroup');
    g.attr('transform', transform);
  };
  useEffect(() => {
    if (!points) return;

    const timeout = setTimeout(() => {
      const g = d3.select('#pointGroup');
      const circles = points.map((it, i) => {
        const prefix = `${it.name}_${Math.random()}`.replace('.', '_');
        const id = `id_${prefix}`;
        const idText = `id_text_${prefix}`;
        return {
          x: it.xposition,
          y: it.yposition,
          color: it.color,
          id,
          idText,
          name: it.name,
          className: '.point'
        };
      });
      g.selectAll('circle')
        .data(circles)
        .join('circle')
        .attr('id', (d) => d.id)
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => d.y)
        .attr('r', SVGEDITOR_POINT_RADIUS)
        .attr('fill', 'transparent')
        .attr('stroke', (d) => d.color)
        .attr('name', (d) => d.name)
        .on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move');
        })
        .on('mouseout', function (d) {})
        .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended));
      /* add text */
      g.selectAll('text')
        .data(circles)
        .join('text')
        .attr('id', (d) => d.idText)
        .attr('x', (d) => d.x - SVGEDITOR_LABEL_SPACING - 30)
        .attr('y', (d) => d.y - SVGEDITOR_LABEL_SPACING + 10)
        .style('font-size', '18px')
        .style('fill', (d) => d.color)
        .text((d) => d.name);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [points]);

  /** drag functions */
  const dragstarted = (event, d) => {
    d3.select(`#${d.id}`).raise().attr('stroke', 'black');
    d3.select('#pointGroup').attr('cursor', 'grabbing');
  };

  const dragged = (event, d) => {
    const idCircle = d.id;
    const idText = d.id.replace('id', 'id_text');
    d3.select(`#${idCircle}`)
      .attr('cx', (d.x = event.x))
      .attr('cy', (d.y = event.y));
  };

  const dragended = (event, d) => {
    const idCircle = d.id;
    const idText = d.id.replace('id', 'id_text');
    d3.select(`#${idCircle}`).attr('stroke', d.color);
    d3.select('#pointGroup').attr('cursor', 'grab');
    const xTo = d3.select(`#${idCircle}`).attr('cx');
    const yTo = d3.select(`#${idCircle}`).attr('cy');
    d3.select(`#${idText}`)
      .attr('x', xTo - SVGEDITOR_LABEL_SPACING - 30)
      .attr('y', yTo - SVGEDITOR_LABEL_SPACING + 10);
  };
  /* validate xml */
  const handleValidate = async () => {
    try {
      if (!svgRef || !svgRef.current) return;
      if (Number(distanceM) < 1) {
        throw new Error('Veuillez renseigner la distance entre 2 points de mesures');
      }

      //d3.select(svgRef.current).transition().duration(750).call(zoom.transform, d3.zoomIdentity);

      const svg = d3.select(svgRef.current);
      svg
        .transition()
        .duration(TRANSITION_TIME)
        .selectAll('g')
        .attr('transform', 'translate(0,0) scale(1)');
      timer.current = setTimeout(async () => {
        try {
          const m1 = d3
            .select('#pointGroup')
            .selectAll('circle')
            .filter(function (d) {
              return d.id.includes('M1');
            });
          const m2 = d3
            .select('#pointGroup')
            .selectAll('circle')
            .filter(function (d) {
              return d.id.includes('M2');
            });
          const distancePix = distanceBetweenPoints(
            [m1.attr('cx'), m1.attr('cy')],
            [m2.attr('cx'), m2.attr('cy')]
          );
          const obj = {
            ...editRadio,
            firstpoint: {
              xposition: m1.attr('cx'),
              yposition: m1.attr('cy'),
              name: m1.attr('name'),
              color: m1.attr('stroke')
            },
            secondpoint: {
              xposition: m2.attr('cx'),
              yposition: m2.attr('cy'),
              name: m2.attr('name'),
              color: m2.attr('stroke')
            },
            distancePixel: distancePix,
            distance: distanceM,
            unity: 'mm',
            xmlData: xmlToBase64(toSvgData(document.getElementById('svg')))
          };

          await saveRadioStep(obj);
          await initPointTracing();
          notif('Sauvegarde réussie');
        } catch (error) {
          notif(null, error);
        }
      }, Number(TRANSITION_TIME + 50));
    } catch (error) {
      notif(null, error);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
          zIndex: 1300
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div">
            Modifier les points de mesures.
          </Typography>
          {editPoint && editPoint.label && editPoint.refstep ? (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ backgroundColor: editPoint.refstep.color, p: 1 }}
            >
              {`${editPoint ? editPoint.label : ''}`}
            </Typography>
          ) : (
            <></>
          )}

          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: SVGEDITOR_TOOLBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SVGEDITOR_TOOLBAR_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ mt: 4 }}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end">mm</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight'
                }}
                value={distanceM}
                onChange={(e) => setdistanceM(e.target.value)}
              />
              <FormHelperText id="outlined-weight-helper-text">
                Distance entre 2 points
              </FormHelperText>
            </FormControl>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 2.5
              }}
              onClick={() => handleValidate()}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center'
                }}
              >
                <DoneIcon />
              </ListItemIcon>
              <ListItemText primary="Valider les mesures" sx={{ opacity: 1 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          px: 3,
          width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
          marginTop: `${toolbar?.minHeight + 10}px`,
          overflow: 'hidden'
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="svg"
          ref={svgRef}
          viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
          style={{
            backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
            width: `100%`
          }}
          preserveAspectRatio="xMinYMin"
        >
          <g id="globalGroup" cursor="grab">
            <image width="100%" height="100%" xlinkHref={img64} />
            <g id="pointGroup" />
          </g>
        </svg>
      </Box>
    </Box>
  );
}
