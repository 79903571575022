import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { LoadingButton } from '@mui/lab';

import { fDateTimeSuffix } from 'utils/formatTime';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import CropImg from 'components/crop/CropImg';
import {
  API_URL,
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_TOOLBAR_WIDTH
} from 'config/appConfig';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
// ----------------------------------------------------------------------
export default function EditRadioBtn({ editRadio }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        {editRadio ? <>Editer la radiologie</> : <>Ajouter une radiologie référentiel</>}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
            zIndex: 1300
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              {editRadio ? (
                <>Editer la radiologie référentiel</>
              ) : (
                <>Ajouter une radiologie référentiel</>
              )}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <EditForm editRadio={editRadio} />
      </Dialog>
    </>
  );
}

const EditForm = ({ editRadio }) => {
  const [imageCropped, setimageCropped] = useState(null);
  const [changeMode, setchangeMode] = useState(false);

  useEffect(() => {
    if (!editRadio) return;
  }, [editRadio]);

  const onValidBlobCrop = async (originImg, imgBlob) => {
    try {
      setimageCropped({ imgBlob, originImg });
      setchangeMode(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangeMode = () => {
    setchangeMode(true);
    setimageCropped(null);
  };
  return (
    <Box sx={{ m: 16 }}>
      {editRadio ? (
        <>
          {changeMode ? (
            <CropImg onValidBlobCrop={onValidBlobCrop} onCancel={() => setchangeMode(false)} />
          ) : (
            <RadioFormData
              editRadio={editRadio}
              imageCropped={imageCropped}
              onChangeImg={handleChangeMode}
            />
          )}
        </>
      ) : (
        <>
          {imageCropped ? (
            <RadioFormData
              editRadio={editRadio}
              imageCropped={imageCropped}
              onChangeImg={handleChangeMode}
            />
          ) : (
            <CropImg
              onValidBlobCrop={onValidBlobCrop}
              editRadio={editRadio}
              onCancel={() => setchangeMode(false)}
            />
          )}
        </>
      )}
    </Box>
  );
};
/*   <CropImg
              onValidBlobCrop={onValidBlobCrop}
              editRadio={editRadio}
              onCancel={() => setchangeMode(false)}
            /> */
const RadioFormData = ({ editRadio, imageCropped, onChangeImg }) => {
  const { notif } = useNotification();
  const { saveRadio, saveRadioFormData, initRefs } = useReferentiel();
  const radioSchema = Yup.object().shape({
    name: Yup.string().max(100).required(`Veuillez renseigner le nom de la radiologie`)
  });
  const formik = useFormik({
    initialValues: {
      name: editRadio && editRadio.name ? editRadio.name : ''
    },
    validationSchema: radioSchema,
    onSubmit: async () => {
      try {
        const { name } = values;

        if (imageCropped && imageCropped.imgBlob && imageCropped.originImg) {
          const formData = new FormData();
          formData.append('originImage', imageCropped.originImg);
          formData.append('image', imageCropped.imgBlob, imageCropped.originImg.name);
          formData.append('name', name);
          await saveRadioFormData(editRadio, formData);
        } else {
          await saveRadio({ ...editRadio, name });
        }
        await initRefs();
        notif('Sauvegarde réussie');
      } catch (error) {
        console.error(error);
        notif(null, error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const getImg = () => {
    try {
      if (imageCropped && imageCropped.imgBlob) {
        return <Img imgBlob={imageCropped.imgBlob} sx={{ width: 200, height: 'auto' }} />;
      }
      if (editRadio) {
        const imageUrl = `${API_URL.file}/${editRadio.imgFile}`;
        return (
          <ImgBoxFromUrl
            image={imageUrl}
            sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
          />
        );
      }
      return <></>;
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Box
          sx={{ width: '100%' }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {getImg()}

          <Button sx={{ my: 3 }} color="error" variant="outlined" onClick={() => onChangeImg()}>
            Modifier
          </Button>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ mb: 5 }}>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                label="Nom"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Valider
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Grid>
    </Grid>
  );
};
const Img = ({ imgBlob, sx }) => {
  const [imageObj, setimageObj] = useState(null);

  useEffect(() => {
    if (!imgBlob) return;
    const imageDataUrl = URL.createObjectURL(imgBlob);
    setimageObj(imageDataUrl);
    return () => {
      setimageObj(null);
    };
  }, [imgBlob]);

  if (!imgBlob) return <></>;
  return (
    <>
      <Box component="img" alt="" src={imageObj} sx={{ ...sx }} />
    </>
  );
};
